
import Vue from 'vue';

import { generalActions } from '@/store/modules/general/names';
import { ComposedState, IReview } from '@/types';

import { mapActions, mapState } from 'vuex';

import AddReviewer from '@/views/reviews/AddReview.vue';
import Loader from '@/components/Loader.vue';

import rest from '@/api/index';

interface IReviewsOptions {
    page: number;
    itemsPerPage?: number;
    size?: number;
    lang: string | null;
};

export default Vue.extend({
    name: 'ReviewsTable',

    components: { AddReviewer, Loader },

    data() {
        return {
            footerProps: {
                'items-per-page-options': [2, 5, 10, 15],
            },

            headers: [
                { text: 'Priority', value: 'sort' },
                { text: 'Fullname', value: 'name' },
                { text: 'Photo', value: 'photo' },
                { text: 'Text', value: 'text' },
                { text: 'Language', value: 'lang' },
                { text: 'Activity', value: 'is_active' },
            ],

            filters: {
                filterLangs: null,
            },

            options: {} as IReviewsOptions,
            loading: true,

            isEditField: {
                name: false,
                text: false,
                lang: false,
                sort: false,
                photo: false,
                is_active: false,
            },

            nameLengthRule: (v) => v.length <= 30 || 'Name too long!',
            textLengthRule: (v) => v.length <= 400 || 'Max length 400 symbols!',
            textMinLengthRule: (v) => v.length >= 10 || 'Min length 10 symbols!',
            fieldRequiredRule: (v) => v.length !== 0 || 'Field required!',

            newPhoto: null as null | Blob,
            currentReview: null as null | IReview,
            isShowLoader: false,
            tempReviewField: null,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            languages: (state: ComposedState) => state.general.langs,
            reviews: (state: ComposedState) => state.general.reviews,
            reviewsTotal: (state: ComposedState) => state.general.reviewsTotal,
        }),

        sortOptions(): IReviewsOptions {
            return {
                page: this.options.page,
                size: this.options.itemsPerPage,
                lang: this.filters.filterLangs,
            };
        },
    },

    watch: {
        'filters.filterLangs': {
            async handler() {
                this.options.page = 1;
                await this.withOptions();
            },
            deep: true,
        },
    },

    async mounted() {
        await this.fetchLangs();
    },

    methods: {
        ...mapActions({
            fetchLangs: generalActions.fetchLangs,
            getReviews: generalActions.getReviews,
        }),

        async save(field: string, id: number) {
            this.removeDisable();
            const formData = new FormData();

            // @ts-ignore
            Object.keys(this.currentReview).forEach((key) => {
                if (key === 'photo') {
                    // @ts-ignore
                    formData.append(key, this.newPhoto ? this.newPhoto : this.currentReview[key]);
                } else {
                    // @ts-ignore
                    formData.append(key, this.currentReview[key]);
                }
            });

            // @ts-ignore
            if (!this.currentReview[field].length && field !== 'is_active') {
                // @ts-ignore
                this.currentReview[field] = this.tempReviewField;
                return;
            }

            // @ts-ignore
            if (field === 'text' && this.currentReview[field].length < 10) {
                // @ts-ignore
                this.currentReview[field] = this.tempReviewField;
                return;
            }

            this.isShowLoader = true;
            await rest.updateReview(id, formData)
                .then(async () => await this.getReviews())
                .finally(() =>  this.isShowLoader = false);

            this.isEditField[field] = false;
            this.newPhoto = null;
        },

        open(field: string, id: number) {
            this.addDisable();

            this.getCurrentReview(id);
            this.isEditField[field] = true;

            // @ts-ignore
            if (this.currentReview[field]) {
                // @ts-ignore
                this.tempReviewField = this.currentReview[field];
            }
        },

        addDisable() {
            const element = document.querySelector('[data-name="table"]');
            const item = element ? element as HTMLElement : null;
            if (item && item.parentElement) {
                item.parentElement.style.pointerEvents = 'none';
                item.parentElement.style.opacity = '0.4';
            };
        },

        removeDisable() {
            const element = document.querySelector('[data-name="table"]');
            const item = element ? element as HTMLElement : null;
            if (item && item.parentElement) {
                item.parentElement.style.pointerEvents = 'auto';
                item.parentElement.style.opacity = '1';
            };
        },

        previewImage() {
            const fr = new FileReader();

            if (this.newPhoto) {
                fr.readAsDataURL(this.newPhoto);

                // @ts-ignore
                fr.onload = (e) => this.$refs.uploadPreview.src = e.target ? e.target.result : null;
            }
        },

        getCurrentReview(id: number) {
            // @ts-ignore
            this.currentReview = this.reviews.find((item: IReview) => item.id === id);
        },

        async withOptions() {
            await this.getReviews({ ...this.sortOptions });
            this.loading = false;
        },
    },
});
