
import Vue from 'vue';

import { ComposedState, IReview } from '@/types';
import { mapActions, mapState } from 'vuex';

import { maxLength, minLength, required } from 'vuelidate/lib/validators';

import rest from '@/api/index';
import { generalActions } from '@/store/modules/general/names';
import { onlyLettersValidate } from '../../tools/validator';

export default Vue.extend({
    name: 'AddReviewer',

    validations: {
        reviewer: {
            name: {
                required,
                maxLength: maxLength(30),
                onlyLettersValidate,
            },
            photo: { required },
            text: { 
                required, 
                maxLength: maxLength(400),
                minLength: minLength(10),
            },
            lang: { required },
            is_active: { required },
            sort: { required },
        },
    },

    data() {
        return {
            isShowAddReviewerModal: false,

            reviewer: {
                name: null,
                photo: null,
                text: null,
                lang: null,
                is_active: false,
                sort: 500,
            } as IReview,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            languages: (state: ComposedState) => state.general.langs,
        }),
        
        errorName(): string  {
            let error: string = '';
            if (!this.$v.reviewer.name?.$dirty) return error;
            !this.$v.reviewer.name.required && (error = 'Name is required.');
            !this.$v.reviewer.name.maxLength && (error = 'Max length 30 symbols.');          
            !this.$v.reviewer.name.onlyLettersValidate && (
                error = 'Name must contain only letters'
            );
            return error;
        },

        errorText(): string  {
            let error: string = '';
            if (!this.$v.reviewer.text?.$dirty) return error;
            !this.$v.reviewer.text.required && (error = 'Text is required.');
            !this.$v.reviewer.text.maxLength && (error = 'Max length 400 symbols.');
            !this.$v.reviewer.text.minLength && (error = 'Min length 10 symbols.');
            return error;
        },

        errorPriority(): string  {
            let error: string = '';
            if (!this.$v.reviewer.sort?.$dirty) return error;
            !this.$v.reviewer.sort.required && (error = 'Priority is required.');
            return error;
        },

        errorPhoto(): string  {
            let error: string = '';
            if (!this.$v.reviewer.photo?.$dirty) return error;
            !this.$v.reviewer.photo.required && (error = 'Photo is required.');
            return error;
        },

        errorLang(): string {
            let error: string = '';
            if (!this.$v.reviewer.lang?.$dirty) return error;
            !this.$v.reviewer.lang.required && (error = 'Language is required.');
            return error;
        },
    },

    methods: {
        ...mapActions({
            getReviews: generalActions.getReviews,
        }),

        previewImage() {
            const fr = new FileReader();
            if (this.reviewer.photo) {
                fr.readAsDataURL(this.reviewer.photo);

                // @ts-ignore
                fr.onload = (e) => this.$refs.reviewerPhoto.src = e.target ? e.target.result : null;
            }
        },

        async addReview() {
            if (this.$v.reviewer.$invalid) {
                this.$v.reviewer.$touch();
                return;
            }

            const formData = new FormData();

            Object.keys(this.reviewer).forEach((key) => {
                formData.append(key, this.reviewer[key]);
            });

            console.log(formData);
            
            const res = await rest.createReview(formData);

            if (res) {
                this.isShowAddReviewerModal = false;
                this.reviewer = {
                    id: 0,
                    name: null,
                    photo: null,
                    text: null,
                    lang: null,
                    is_active: false,
                    sort: 500,
                };

                await this.getReviews();
            };
        },
    },
});
